import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Remove the token from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('addToDesktopClosed');
    localStorage.removeItem('notifyClosed');


    // Redirect to the login page
    navigate('/login');
  }, [navigate]);

  return null; // This component doesn't need to render anything
};

export default Logout;
